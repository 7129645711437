import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { map } from 'ramda'
import styled from 'styled-components'
import Layout from 'components/Layout'
import SEO from '../components/SEO'

const List = styled.div`
  display: flex;
  flex-flow: wrap row;
  justify-content: space-evenly;
`
const Item = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: nowrap column;
  box-shadow: 1px 2px 8px #d4d4d5;
  margin: 0 0 1rem 0;
  padding: 1rem;
  flex-basis: 300px;
`
const Image = styled(Img)`
  width: 100%;
  height: auto;
  margin-bottom: 0.5rem;
`
const Title = styled(Link)`
  font-size: 1.5rem;
  font-family: Source Sans Pro, sans-serif;
  color: #ba2c73;
`
const Subject = styled.span`
  opacity: 0.7;
  margin-bottom: 0.5rem;
`
const Description = styled.div``

const renderMembers = map(({ node: member }) => {
  return (
    <Item key={member.id}>
      <Image fluid={member.image.fluid} />
      <Title to={`/team/${member.slug}`}>{member.name}</Title>
      <Subject>{member.subjects}</Subject>
      {member.description ? (
        <Description
          dangerouslySetInnerHTML={{
            __html: member.description.childMarkdownRemark.excerpt,
          }}
        />
      ) : null}
    </Item>
  )
})

const Component = ({ data }) => {
  const {
    allContentfulMember: { edges: members },
  } = data

  // Generate data for json-ld
  const json = members.map(member => ({
    '@type': 'Person',
    name: member.node.name,
    jobTitle: member.node.subjects,
  }))
  const jsonld = { '@graph': json }
  return (
    <Layout>
      <SEO data={{ title: 'Team' }} jsonld={jsonld} />
      <h1> Team </h1>
      <List>{renderMembers(members)}</List>
    </Layout>
  )
}

export const Members = graphql`
  query memberQuery {
    allContentfulMember(sort: { fields: sort, order: ASC }) {
      edges {
        node {
          id
          name
          subjects
          slug
          description {
            id
            childMarkdownRemark {
              excerpt
            }
          }
          image {
            fluid(maxWidth: 300, maxHeight: 300) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default Component
